import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { FC } from 'react';
import { Spinner } from 'shamrock-clover-ui';
import { useAuth0Flag } from '../../hooks/useFeatureFlag';
import './protectedComponent.scss';
import { AuthService } from '../../services';

const authService = new AuthService();

const backwardsCompatAuthentication = (Component: FC<any>) => {
  return function BackwardsCompatAuthentication(props: any) {
    const isOldAuth = authService.isAuthed;
    const { isAuth0Enabled } = useAuth0Flag();
    return isOldAuth || !isAuth0Enabled ? (
      <Component {...props} />
    ) : (
      <AuthReqComponent Component={Component} {...props} />
    );
  };
};
const AuthReqComponent: FC<{ Component: FC<any> }> = ({ Component, ...prop }) => {
  return withAuthenticationRequired(Component, {
    onRedirecting: () => (
      <div className="spinnerContainer">
        <Spinner />
      </div>
    ),
    loginOptions: {
      openUrl: (url) => {
        window.location.replace(url);
      }
    },
  })(prop);
};
interface ProtectedComponentProps {
  children: any;
}
const ProtectedComponent: FC<ProtectedComponentProps> = ({ children }) => ({
...children,
});
export default backwardsCompatAuthentication(ProtectedComponent);
