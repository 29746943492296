const Config = require('Config');
export function buildURLQuery(params: Record<string, any>) {
  const queryParams = new URLSearchParams();
  for (const [key, val] of Object.entries(params)) {
    if (val) {
      queryParams.append(key, val as string);
    }
  }

  return queryParams;
}

export function redirectoToWebCore(
  token: string,
  email?: string,
  finaldestination?: string
) {
  const authQuery = `?token=${encodeURIComponent(
    token
  )}&email=${email}&finaldestination=${finaldestination}`;
  window.open(`${Config.coreWebUrl}auth${authQuery}`, '_blank');
}

export function redirectToSettings(
) {
  window.open(`${Config.coreWebUrl}settings`, '_blank');
}
