// packages
import React, { useState } from 'react';

// components
import StepNavigation from './StepNavigation';

// services
import registrationService from '../../services/RegistrationService';

// styles
import {
  StepContainerPaper,
  StepContentLabel,
  StepTitleLabel,
  TextInputStyled,
  TitleLabel
} from './RegistrationStyles';

// utils
import { isValidEmail } from '../../utilities/stringHelper';

// props
interface Props {
  isLoading: boolean;
  onCancel: () => void;
  onChangeStep: (value: number) => void;
  onChangeUserEmail: (value: string) => void;
  setCustomCardMsg: (value: string) => void;
  step: number;
  toggleCardVisibility: (value: boolean) => void;
  toggleError: (value: boolean) => void;
  toggleIsLoading: (value: boolean) => void;
  userEmail: string;
  visible: boolean;
}

export const Step1 = ({
  isLoading,
  onCancel,
  onChangeStep,
  onChangeUserEmail,
  step,
  toggleCardVisibility,
  setCustomCardMsg,
  toggleError,
  toggleIsLoading,
  userEmail,
  visible
}: Props) => {
  const [isSuccess, toggleSuccess] = useState<boolean>(false);

  const handleOnError = () => {
    toggleCardVisibility(true);
    toggleError(true);
    toggleIsLoading(false);
    setCustomCardMsg(
      'Unable to send a verification code. Please try again later.'
    );
    toggleSuccess(false);
    setTimeout(() => toggleCardVisibility(false), 3500);
  };

  const handleOnSucess = () => {
    toggleCardVisibility(true);
    toggleError(false);
    setCustomCardMsg('Verification code sent');
    toggleIsLoading(false);
    toggleSuccess(true);

    setTimeout(() => {
      toggleIsLoading(true);
    }, 1500);
    setTimeout(() => {
      toggleCardVisibility(false);
      setCustomCardMsg('');
      toggleSuccess(false);
      onChangeStep(step + 1);
      toggleIsLoading(false);
    }, 3500);
  };

  const handleOnSendVerificationCode = async () => {
    toggleIsLoading(true);

    const response:
      | {
          success: boolean;
        }
      | undefined = await registrationService.sendVerificationCode(userEmail);

    if (response?.success) {
      handleOnSucess();
      return;
    }

    handleOnError();
  };

  if (!visible) {
    return null;
  }

  return (
    <>
      <StepContainerPaper height="">
        <TitleLabel>Create your Ryan Transportation Account</TitleLabel>
        <StepTitleLabel>Step 1 of 3</StepTitleLabel>
        <StepContentLabel>
          To start we need to verify your information. Where should we send the
          verification code?
        </StepContentLabel>
        <TextInputStyled
          label="Email address"
          onChange={({ target: { value } }: { target: { value: string } }) =>
            onChangeUserEmail(value)
          }
          value={userEmail}
        />
        <StepNavigation
          isLeftButtonDisabled={isLoading}
          isRightButtonDisabled={
            !isValidEmail(userEmail) || isLoading || isSuccess
          }
          onBack={onCancel}
          onContinue={handleOnSendVerificationCode}
          visible
        />
      </StepContainerPaper>
    </>
  );
};

export default Step1;
