import orderService from '../services/order/OrderService';
import userService from '../services/user/UserService';
import addressBookService from '../services/AddressBookService';
import emailService from '../services/EmailService';
import featureService from '../services/FeatureService';
import invoiceService from '../services/InvoiceService';
import loadTrackingService from '../services/LoadTrackingService';
import notificationService from '../services/NotificationService';
import orderEmailService from '../services/OrderEmailService';
import quoteEmailService from '../services/QuoteEmailService';
import registrationService from '../services/RegistrationService';
import userDataService from '../services/UserData';
import userMcleodService from '../services/UserMcleodService';

export const addInterceptorsToServices = async (
  getAccessTokenSilently: () => Promise<string>,
  isAuth0Enabled: boolean,
  logout: () => Promise<void>,
) => {
  await Promise.all([
    orderService.addInterceptors(getAccessTokenSilently, isAuth0Enabled, logout),
    userService.addInterceptors(getAccessTokenSilently, isAuth0Enabled, logout),
    addressBookService.addInterceptors(getAccessTokenSilently, isAuth0Enabled, logout),
    emailService.addInterceptors(getAccessTokenSilently, isAuth0Enabled, logout),
    featureService.addInterceptors(getAccessTokenSilently, isAuth0Enabled, logout),
    invoiceService.addInterceptors(getAccessTokenSilently, isAuth0Enabled, logout),
    loadTrackingService.addInterceptors(getAccessTokenSilently, isAuth0Enabled, logout),
    notificationService.addInterceptors(getAccessTokenSilently, isAuth0Enabled, logout),
    orderEmailService.addInterceptors(getAccessTokenSilently, isAuth0Enabled, logout),
    quoteEmailService.addInterceptors(getAccessTokenSilently, isAuth0Enabled, logout),
    registrationService.addInterceptors(getAccessTokenSilently, isAuth0Enabled, logout),
    userDataService.addInterceptors(getAccessTokenSilently, isAuth0Enabled, logout),
    userMcleodService.addInterceptors(getAccessTokenSilently, isAuth0Enabled, logout),
  ]);
};
