import React, { Component, Fragment } from 'react';
import { AuthService } from '../../services';
import { withAuth0, WithAuth0Props } from '@auth0/auth0-react';

const Config = require('Config');

interface LogoutProps extends WithAuth0Props {}

class Logout extends Component<LogoutProps> {
  authService: AuthService;

  constructor(props: LogoutProps) {
    super(props);
    this.authService = new AuthService();
  }

  componentDidMount() {
    if (this.props.auth0.isAuthenticated) {
      this.authService.deleteUser();
      this.props.auth0.logout({
        logoutParams: { returnTo: Config.shipperPortalUrl }
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  render() {
    return (
      <Fragment>
        <p>You have been logged out. Returning home.</p>
      </Fragment>
    );
  }
}

export default withAuth0(Logout);
