// packages
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { withAuth0, WithAuth0Props } from '@auth0/auth0-react';

// components
import LoginLanding from '../loginLanding';

// services
import { AuthService, Token, UserService } from '../../services';

// utils
import * as FirebaseUtils from '../../utilities/firebaseUtils';
import { addInterceptorsToServices } from '../../utilities/requestInterceptors';

const Config = require('Config');

interface LoginProps extends RouteComponentProps, WithAuth0Props {}

class Login extends Component<LoginProps> {
  authService: AuthService;

  constructor(props: LoginProps) {
    super(props);

    this.authService = new AuthService();
  }

  componentDidUpdate() {
    if (this.authService.isAuthed || this.props.auth0.isAuthenticated) {
      this.props.history.replace('/shipments');
      return;
    }
  }

  async componentDidMount() {
    try {
      let email: string | undefined;
      try {
        const tokenResp:
          | Token
          | undefined = await this.authService.checkSession();
        if (tokenResp) {
          this.authService.setToken(tokenResp);
        }
        email = this.authService.getToken?.user;
      } catch (e) {}
      if (this.authService.isAuthed || this.props.auth0.isAuthenticated) {
        await addInterceptorsToServices(
          this.props.auth0.getAccessTokenSilently,
          this.props.auth0.isAuthenticated,
          this.props.auth0.logout
        );
      }
      if (this.props.auth0.isAuthenticated) {
        const claims = await this.props.auth0.getIdTokenClaims();
        email = claims?.upn || claims?.email;
      }
      await UserService.isUserValid(email);
      const currentUser = UserService.getCurrentUser();

      if (currentUser) {
        Sentry.addBreadcrumb({
          category: 'auth',
          message: 'Authenticated user ' + currentUser.email,
          level: Sentry.Severity.Info
        });
        ReactGA.set({
          dimension1: currentUser._id,
          dimension2: currentUser._id,
          dimension3: currentUser._id
        });
        ReactGA.event({
          category: 'User',
          action: 'Login',
          label: currentUser._id
        });

        FirebaseUtils.logFirebaseUser(currentUser._id, currentUser.user_type);
      }
      this.props.history.replace('/shipments');
    } catch (e) {
      if (e === 403) {
        if (this.props.auth0.isAuthenticated) {
          this.authService.deleteUser();
          await this.props.auth0.logout({
            logoutParams: { returnTo: Config.shipperPortalUrl }
          });
        } else {
          this.authService.logoutRedirect();
        }
      }
    }
  }

  render() {
    if (this.authService.isAuthed || this.props.auth0.isAuthenticated) {
      return null;
    }

    return <LoginLanding />;
  }
}

export default withAuth0(withRouter(Login));
