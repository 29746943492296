import Axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import AuthService from './auth/AuthService';
export default class Service {
  axios: AxiosInstance;
  authenticate: boolean;

  constructor(serviceUrl: string, authenticate: boolean = true) {
    this.axios = Axios.create({
      baseURL: serviceUrl
    });
    this.authenticate = authenticate;
  }

  async addInterceptors(tokenGenerator: () => Promise<string>, isAuth0Enabled: boolean, logout: () => Promise<void>) {
    this.axios.interceptors.request.use(
      async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        if (this.authenticate) {
          let token = localStorage.getItem('token');
          if (token) {
            token = JSON.parse(token).token;
            config.headers.Authorization = `Bearer ${token}`;
          } else if (tokenGenerator) {
            const generatedToken = await tokenGenerator();
            config.headers.Authorization = `Bearer ${generatedToken}`;
          }
        }
        return config;
      },
      (error: AxiosError) => Promise.reject(error),
    );
    this.axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (this.authenticate && (error?.response?.status === 401 || error?.response?.status === 403)) {
          if (isAuth0Enabled) {
            new AuthService().deleteUser();
            await logout();
          } else {
            new AuthService().logoutRedirect();
          }
        }
        return Promise.reject(error);
      },
    );
  }
}
