// packages
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { NavLink } from 'react-router-dom';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useLocation } from 'react-router';

// components
import { Slide } from '@material-ui/core';
import AppHeader from './components/appHeader/AppHeader';
import Login from './containers/login/Login';
import Logout from './containers/login/Logout';
import Orders from './containers/orders/Orders';
import RequestQuoteButton from './features/requestQuote/RequestQuoteButton';
import RequestQuoteContainer from './features/requestQuote/container/requestQuoteContainer';
import { Accounting } from './features/accounting';
import { LoadTracking } from './features/loadTracking';
import { OptOut } from './features/optOut';
import { Snackbar } from 'shamrock-clover-ui/dist/clover/components/Snackbar/Snackbar';

// services
import { AuthService, UserService } from './services';

// utils
import * as FirebaseUtils from './utilities/firebaseUtils';
import ProtectedComponent from './components/protectedComponent/ProtectedComponent';
import { useAuth0 } from '@auth0/auth0-react';
import { addInterceptorsToServices } from './utilities/requestInterceptors';
import { Spinner } from 'shamrock-clover-ui';
import './components/protectedComponent/protectedComponent.scss';
import { useAuth0Flag } from './hooks/useFeatureFlag';

const Config = require('Config');

const AuthRouteWithHeader: React.FC<{
  Component?: any;
  path: string;
  isAuthenticated: boolean;
}> = ({ path, children, Component, isAuthenticated }) => {
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false);
  const [requestQuoteBanner, setRequestQuoteBanner] = useState<{
    isVisible: boolean;
    isError: boolean;
    value: string;
  }>({ isVisible: false, isError: false, value: '' });

  return isAuthenticated ? (
    <Route path={path} exact>
      <AppHeader>
        <div className="headerContent">
          <div className="leftHeader">
            <NavLink
              to="/shipments"
              className="navItem"
              activeClassName="active"
            >
              Shipments
            </NavLink>
            <NavLink
              to="/accounting"
              className="navItem"
              activeClassName="active"
            >
              Accounting
            </NavLink>
          </div>
          <RequestQuoteButton onClick={setIsQuoteModalOpen} visible />
        </div>
      </AppHeader>
      <div className="appContentContainer">
        <Snackbar
          open={requestQuoteBanner.isVisible}
          onClose={() => {
            setRequestQuoteBanner({
              isVisible: false,
              isError: false,
              value: ''
            });
          }}
          message={requestQuoteBanner.value}
          variant={requestQuoteBanner.isError ? 'error' : 'success'}
        />
        <Slide
          direction="left"
          in={isQuoteModalOpen}
          mountOnEnter
          unmountOnExit
          timeout={{ enter: 400, exit: 400 }}
        >
          <RequestQuoteContainer
            toggleRequestQuoteModal={setIsQuoteModalOpen}
            isRequestQuoteVisible={isQuoteModalOpen}
            setRequestQuoteBanner={setRequestQuoteBanner}
          />
        </Slide>
        {Component ? (
          <Component isQuoteModalOpen={isQuoteModalOpen} />
        ) : (
          children
        )}
      </div>
    </Route>
  ) : (
    <Redirect to="/" />
  );
};

const App: FunctionComponent<{
  authService: AuthService;
}> = ({ authService }) => {
  const { pathname } = useLocation();
  const {
    isAuthenticated: isAuth0Auth,
    isLoading: isAuth0Loading,
    getAccessTokenSilently,
    getIdTokenClaims,
    logout
  } = useAuth0();
  const { isAuth0Enabled } = useAuth0Flag();
  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticated = authService.isAuthed || isAuth0Auth;
  let email = authService?.getToken?.user;

  useEffect(() => {
    ReactGA.set({
      page: pathname
    });
    ReactGA.pageview(pathname);
    FirebaseUtils.logFirebaseScreenName(FirebaseUtils.FirebasePages.SHIPMENTS);
    FirebaseUtils.logPageView(FirebaseUtils.FirebasePages.SHIPMENTS);
  }, [pathname]);

  useEffect(() => {
    const getMostCurrentUser = async () => {
      try {
        if (isAuthenticated) {
          await addInterceptorsToServices(getAccessTokenSilently, isAuth0Auth, logout);
          if (isAuth0Auth) {
            if (!isAuth0Enabled) await logout({ logoutParams: { returnTo: Config.shipperPortalUrl } });
            const claims = await getIdTokenClaims();
            email = claims?.upn || claims?.email;
          }
          await UserService.getUser(email);
        }
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    };
    if(!isAuth0Loading) getMostCurrentUser();
  }, [isAuth0Loading]);

  return (
    <Fragment>
      {isLoading ? (
        <div className="spinnerContainer">
          <Spinner />
        </div>
      ) : (
        <>
          <Route path="/" component={Login} exact />
          <Route path="/logout" component={Logout} exact />
          <Switch>
            <Route path="/optOut" component={OptOut} exact />
            <Route path="/loadTracking" component={LoadTracking} exact />
            <ProtectedComponent path="/shipments">
              <AuthRouteWithHeader
                Component={Orders}
                isAuthenticated={isAuthenticated}
                path="/shipments"
              />
            </ProtectedComponent>
            <ProtectedComponent path="/accounting">
              <AuthRouteWithHeader
                Component={false}
                path="/accounting"
                isAuthenticated={isAuthenticated}
              >
                <Accounting />
              </AuthRouteWithHeader>
            </ProtectedComponent>
            <Redirect from={'*'} to={'/'} />
          </Switch>
        </>
      )}
    </Fragment>
  );
};

export default App;
