import 'shamrock-clover-ui/dist/clover/css/clover.css';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import '../styles/main.scss';
import CloverThemeProvider from 'shamrock-clover-ui/dist/clover/Theme/ThemeProvider';
import { Provider } from 'react-redux';
import store from './store/index';
import ErrorBoundary from './containers/error/ErrorBoundary';
import { AuthService } from './services';
import * as firebaseUtils from './utilities/firebaseUtils';

import { QueryClient, QueryClientProvider } from 'react-query';

// Create a client
const queryClient = new QueryClient();

const Config = require('Config');

firebaseUtils.init(Config.firebaseConfig).catch(error => {
  console.log(error.message);
  //log sentry error
});

ReactGA.initialize(Config.googleAnalyticsId);
ReactGA.pageview(window.location.pathname);

const authService = new AuthService();

ReactDOM.render(
  <Auth0Provider
    domain={Config.auth0CustomDomain} //login domain 'auth.rtspro.dev', should be the same for all teams regardless of application
    clientId={Config.auth0ClientId} //clientId of the application specified above
    authorizationParams={{
      redirect_uri: window.location.origin, //location of your app rtspro.dev
      audience: `https://${Config.auth0Domain}/api/v2/`, // management api url 'https://stc-development.us.auth0.com/api/v2/'
      scope: 'openid profile email offline_access',
    }}
  >
    <Provider store={store}>
      <BrowserRouter>
        <CloverThemeProvider>
          <QueryClientProvider client={queryClient}>
            <ErrorBoundary>
              <App authService={authService} />
            </ErrorBoundary>
          </QueryClientProvider>
        </CloverThemeProvider>
      </BrowserRouter>
    </Provider>
  </Auth0Provider>,
  document.getElementById('root')
);
