// packages
import React, { useState } from 'react';

// components
import StepNavigation from './StepNavigation';

// services
import registrationService from '../../services/RegistrationService';

// styles
import {
  ExpireLabel,
  ExpireLabelContainer,
  LinkStyled,
  StepContainerPaper,
  StepContentLabel,
  StepTitleLabel,
  TextInputStyled,
  TitleLabel
} from './RegistrationStyles';

// props
interface Props {
  isLoading: boolean;
  onChangeStep: (value: number) => void;
  onChangeVerificationCode: (value: string) => void;
  step: number;
  toggleError: (value: boolean) => void;
  toggleIsLoading: (value: boolean) => void;
  userEmail: string;
  verificationCode: string;
  visible: boolean;
}

export const Step2 = ({
  isLoading,
  onChangeStep,
  onChangeVerificationCode,
  step,
  toggleError,
  toggleIsLoading,
  userEmail,
  verificationCode,
  visible
}: Props) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, toggleSuccess] = useState<boolean>(false);

  const handleOnError = () => {
    toggleIsLoading(false);
    setIsError(true);
    toggleSuccess(false);
  };

  const handleOnSucess = () => {
    toggleError(false);
    toggleSuccess(true);

    setTimeout(() => {
      toggleSuccess(false);
      onChangeStep(step + 1);
      toggleIsLoading(false);
    }, 3500);
  };

  const handleOnResendVerificationCode = async () => {
    toggleIsLoading(true);

    const response:
      | {
          success: boolean;
        }
      | undefined = await registrationService.sendVerificationCode(userEmail);

    if (response?.success) {
      toggleError(false);
      toggleSuccess(true);
      toggleIsLoading(false);
      return;
    }

    handleOnError();
  };

  const handleOnCheckVerificationCode = async () => {
    toggleIsLoading(true);
    const response:
      | {
          success: boolean;
        }
      | undefined = await registrationService.checkVerificationCode(
      userEmail,
      verificationCode
    );

    if (response?.success) {
      handleOnSucess();
      return;
    }

    handleOnError();
  };

  const verificationCodeInputError = () => {
    if (verificationCode === '') {
      return '';
    }

    if (verificationCode.length < 4) {
      return 'Enter a 4-digit number';
    }

    if (!isSuccess && isError) {
      return 'Invalid verification code, please try again';
    }

    return '';
  };

  if (!visible) {
    return null;
  }

  return (
    <StepContainerPaper height="">
      <TitleLabel>Enter Verification Code</TitleLabel>
      <StepTitleLabel>Step 2 of 3</StepTitleLabel>
      <StepContentLabel noPadding>
        Enter the 4-digit verification code we sent to:
      </StepContentLabel>
      <StepContentLabel marginBottom="25px" noPadding noLineHeight>
        {userEmail}
      </StepContentLabel>
      <StepContentLabel noPadding>
        Didn’t receive a code? Check your junk mail, Spam folder or
      </StepContentLabel>
      <LinkStyled onClick={handleOnResendVerificationCode}>
        Resend verification code
      </LinkStyled>
      <TextInputStyled
        label="Verification Code"
        maxLength={4}
        error={verificationCodeInputError()}
        marginBottom="70px"
        onChange={({ target: { value } }: { target: { value: string } }) => {
          setIsError(false);
          toggleSuccess(false);

          onChangeVerificationCode(value);
        }}
        value={verificationCode}
      />
      <ExpireLabelContainer>
        <ExpireLabel>Code Expires in 15 mins</ExpireLabel>
      </ExpireLabelContainer>
      <StepNavigation
        isLeftButtonDisabled={isLoading}
        isRightButtonDisabled={verificationCode?.length < 4 || isLoading}
        onBack={() => onChangeStep(step - 1)}
        onContinue={handleOnCheckVerificationCode}
        visible
      />
    </StepContainerPaper>
  );
};

export default Step2;
